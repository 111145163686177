//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import {mapActions, mapState, mapGetters} from 'vuex'
import {getShoppingCartSID} from '@/utils/helpers'
import {adminCodes} from "../../../../../MultiSiteClient/enums/adminSettings";
import forgotPasswordCard from "../common/forgot-password-card";
import ChangePasswordCard from "../common/change-password-card";

export default {
  name: 'user-login',
  components: {
    LabeledControlLayout,
    forgotPasswordCard,
    ChangePasswordCard
  },
  props: {
    redirectTo: {
      type: String,
      required: false,
      default: 'home',
    },
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visibleForm: 'signIn',
      signInForm: {
        data: {
          email: '',
          password: '',
        },
        emailRules: [
          (v) => !!v || 'This is a required field!',
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('Please enter a valid email address!')
        ],
        rules: {
          required: (value) => !!value || 'This is a required field!',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters!',
        },
        valid: false,
        rememberMe: false,
      },
      currentUserName: '',
      currentUserID: 0,
      footerText: '',
    }
  },
  async mounted() {
    let userInfo = this.getSavedUserInfo();
    if(userInfo) {
      this.signInForm.data.email = userInfo.email;
      this.signInForm.data.password = userInfo.password;
      this.signInForm.rememberMe = true;
    }

    this.footerText = this.section?.footer_text?.fieldValue?.value;
  },
  computed: {
    ...mapState('common/cache', ['loadSettings']),
  },
  methods: {
    ...mapActions('backoffice/settings', ['fetchAdminSettingByCode']),
    ...mapActions('common/cache', ['loadingCache']),
    ...mapActions('rememberedUser', ['saveUser','removeSavedUser']),
    ...mapGetters('rememberedUser',['getSavedUserInfo']),
    async localLoginAndRedirectHandler(email, password, checkForRememberedUser = true) {
      try {
        const response = await this.$auth.loginWith('local', {
          data: {
            username: email,
            password: password,
            sessionId: getShoppingCartSID(),
          },
        })
        if (response.status === 200 && response.data.token) {
          if(checkForRememberedUser && this.signInForm.rememberMe) {
            this.saveUser({email: email, password: password})
          }
          this.$toast.success('Logged in!')
          this.$store.dispatch('shoppingCart/frontendGetBySessionId')
          await this.loadingCache()
          const redirectUrl = (await this.fetchAdminSettingByCode(adminCodes.REDIRECT_AFTER_FRONTEND_LOGIN))?.value
          const redirectFromUrl = this.$route?.query?.redirectBackTo?.toString()
          if(redirectFromUrl && redirectFromUrl.length) {
            await this.$router.push(redirectFromUrl)
            return;
          }
          if(redirectUrl) {
            await this.$router.push(redirectUrl)
          } else {
            await this.$router.push(this.redirectTo)
          }

        } else {
          this.$toast.error('Invalid username or password!')
        }
      }catch (e) {
        this.$toast.error('Error while logging in!')
      }
    },
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const changePasswordResponse = await this.$axios.$post(
              `/auth/portal-login-check-must-change-password`,
              {
                username: this.signInForm.data.email,
                password: this.signInForm.data.password,
              }
          )
          if(changePasswordResponse) {
            if (changePasswordResponse.hasErrors && changePasswordResponse.errorMessages.error) {
              this.$toast.error(changePasswordResponse.errorMessages.error)
            } else if (changePasswordResponse.value
                && changePasswordResponse.value.mustChangePasswordOnNextLogin
                && changePasswordResponse.value.mustChangePasswordOnNextLogin === true
                && changePasswordResponse.value.userName
                && changePasswordResponse.value.id) {
              this.visibleForm = 'mustChangePassword'
              this.currentUserName = changePasswordResponse.value.userName;
              this.currentUserID = changePasswordResponse.value.id;
              return
            }
            await this.localLoginAndRedirectHandler(this.signInForm.data.email, this.signInForm.data.password)
          }
        } catch (err) {
          this.$toast.error('Error while logging in!')
        }
      }
    },
    async loginWithNewPassword(loginInfo) {
      await this.localLoginAndRedirectHandler(loginInfo.username, loginInfo.password, false)
    },
    removeRememberedUserOnUncheck() {
      if(!this.signInForm.rememberMe) {
        this.removeSavedUser();
      }
    }
  },
}
